// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color, $end-color, $start-percent: null, $end-percent: null) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
}

.background_app{
  //@include gradient-y(#144D80, $blue-900);
  @include gradient-y(#031633, #144D80);
}

.flash-text {
  animation-name: flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes flash {
  0%  { color: #ffc107 }
  50% { color: #dc3545 }
  100%{ color: #ffc107 }
}