.ghost-effect:hover {
  animation-name: ghost-letter;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes ghost-letter {
  0% {
    opacity: 1;
    filter:blur(1px);
  }
  100% {
    opacity: 0;
    filter:blur(20px);
  }
}