.App {
  text-align: center;
  /*background-color: #e8e8ab;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  /*color: #cbf0a1;*/
  /*color: #a1edf0;*/
  color: #c8cfb2;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }
}

.floating-img {
  animation-name: floating-img;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-top: 5px;
}

@keyframes floating-img {
  0%  { transform: translate(0,  -25px); }
  50% { transform: translate(0,  25px); }
  100%{ transform: translate(0, -25px); }
}

.serif-noto{
  font-family: 'DM Serif Display', serif;
}

.noto-sans{
  font-family: 'Noto Sans Display', sans-serif;
}

.Sun-font{
  font-family: 'Yeon Sung', cursive;
}

.Playfair-title{
  font-family: 'Playfair Display SC', serif;
}

.Playfair{
  font-family: 'Playfair Display', serif;
}

.Ubuntu-font{
  font-family: 'Ubuntu', sans-serif;
}

.img-container { position: relative; }

.img-container .top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.social-logo{
  width:40px;
  height:40px;
  margin-left: 10px;
}

.social-img{
  position: absolute;
}

.ipfs-icon {
  background: url("img/ipfs.png");
  height: 40px;
  width: 40px;
  display: block;
  /* Other styles here */
}

.info-icon:hover{
  transform: translate(0, -25px);
}